// export const Url = "http://localhost:8080"
// export const ImageUrl = "https://doozie-do.cloud"
export const Url = "https://www.doozie-do.co"

export const awsFileUrl = 'https://doozie.s3.ap-south-1.amazonaws.com'

export const orderStatusMessage = {
    pending: 'Pending',
    confirmed: 'Confirmed',
    processing: 'Processing',
    dispatched : "dispatched",
    'out for delivery' : "out for delivery",
    delivered: 'delivered',
    cancelled: 'cancelled'
} 